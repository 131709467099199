import {PublicClientApplication, Configuration} from '@azure/msal-browser';

import { getWindow } from './shared/utils';

const config: Configuration = {
  auth: {
    authority: 'https://login.microsoftonline.com/36da45f1-dd2c-4d1f-af13-5abe46b99921',
    clientId: 'e499e004-8066-4673-9212-9d96b628a7bc',
    redirectUri:  'https://dassuranceproducts.deloitte.com/home',
    postLogoutRedirectUri:  'https://dassuranceproducts.deloitte.com/home'
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true
  }
};
// Authentication Parameters

const msalInstance = new PublicClientApplication(config);

export const loginRequest = {
  scopes: ['user.read']
};

/* // Options//*/
export const options = {  
  tokenRefreshUri: getWindow().APP_CONFIG.GAP_APP_URL
}; 
export default msalInstance;