
import React, {useEffect, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { InteractionRequiredAuthError, AuthenticationResult } from '@azure/msal-browser';

import msalInstance, { loginRequest } from 'authContext';
import {
  getCurrentUser,
  selectIsUserLoading,
  setAccessToken,
  selectHasUser
} from 'store/auth';
import { fetchLocaleList } from 'store/localize';
import { useOnMount } from 'shared/hooks';
import { AppLayout } from 'features/AppLayout';
import { Routes } from 'features/Routes/Routes';
import {
  LOCAL_STORAGE_EXTERNAL_USER_PRODUCT_KEY,
  MSAL_TOKEN_KEY
} from '../../shared/constants/common.consts';
import {
  getProductCodeFromExternalPath,
  getWindow,
} from '../../shared/utils';
import { Spinner } from '../../components';

export const App: React.FC = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
   const msalTokenKeys = localStorage.getItem(MSAL_TOKEN_KEY);
   const msaltokenKey = msalTokenKeys && JSON.parse(msalTokenKeys).idToken[0];
   const tokenJson = localStorage.getItem(msaltokenKey);
   const msalToken = tokenJson && JSON.parse(tokenJson).secret;
  //const msalToken = localStorage.getItem(MSAL_TOKEN_KEY);
  const product = getProductCodeFromExternalPath(pathname);
  const isLoading = useSelector(selectIsUserLoading);
  const hasUser = useSelector(selectHasUser);
  const loginResponseRef = useRef<{ accessToken?: string } | null>(null);
 // const loginResponseRef = useRef<{ accessToken?: string } | null>(null);
 // let loginResponse: any;


 const handleRedirectResponse = async (): Promise<void> => {
  try {
    const response: AuthenticationResult = await msalInstance.handleRedirectPromise();
    if (response) {
      console.log('resoponse token line 50 redirect response in App.tsx :', response.accessToken);
      // eslint-disable-next-line
      const accessToken = response.accessToken;
      console.log('resoponse token line 52 redirect response in App.tsx :', response.accessToken);
      console.log('Access Token in App.tsx line no 53 :', accessToken);
      console.log('resoponse  line 54 redirect response in App.tsx :', response);
      // You can now use the access token for authenticated requests
    }
  } catch (error) {
    console.error('Error handling redirect response:', error);
  }
};


const fetchTokenSilently = async () => {
  try {
    await msalInstance.initialize(); // Ensure MSAL instance is initialized
    const response: AuthenticationResult = await msalInstance.acquireTokenSilent(loginRequest);
    const { accessToken } = response;
    console.log('Access Token acquired silently:', accessToken);
    console.log('response Token acquired silently:', response);
    loginResponseRef.current = { accessToken };
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      console.error('Silent token acquisition failed, interaction required:', error);
      // Fallback to interactive method if silent acquisition fails
      await msalInstance.loginRedirect(loginRequest);
    } else {
      console.error('Error acquiring token silently:', error);
    }
  }
};

useEffect(() => {
  fetchTokenSilently();
  handleRedirectResponse();

 
  //console.log('loginResponse in App.tsx : ', loginResponse);

  console.log('pathname in App.tsx : ', pathname);
  console.log('msalTokenKeys in App.tsx : ', msalTokenKeys);
  console.log('msaltokenKey in App.tsx : ', msaltokenKey);
  console.log('tokenJson in App.tsx : ', tokenJson);
  //console.log('msalTokenyy in App.tsx : ', msalTokenyy);

  console.log('msalToken in App.tsx : ', msalToken);
  console.log('product in App.tsx : ', product);
  console.log('isLoading in App.tsx : ', isLoading);
  console.log('hasUser in App.tsx : ', hasUser);
  // console.log('msalToken in App.tsx : ', msalToken);
  // console.log('isAuthenticated in App.tsx : ', isAuthenticated);
  console.log(
    'getWindow().APP_CONFIG.MOCK_USER in App.tsx : ',
    getWindow().APP_CONFIG.MOCK_USER
  );
  if (msalToken || getWindow().APP_CONFIG.MOCK_USER) {
    if (pathname.includes('termsofuse')) {
      localStorage.setItem(LOCAL_STORAGE_EXTERNAL_USER_PRODUCT_KEY, product);
    }

    console.log('hasUser in App.tsx outside if condition : ', hasUser);
    console.log('msalToken in App.tsx outside if condition : ', msalToken);

    if (!hasUser) {
      console.log('hasUser in App.tsx in if condition : ', hasUser);
      console.log('msalToken in App.tsx in if condition : ', msalToken);
      dispatch(setAccessToken(msalToken));
      dispatch(getCurrentUser());
    }
  }
  // eslint-disable-next-line
}, [dispatch, pathname, product, msalToken]);

  useOnMount(
    React.useCallback((): void => {
      dispatch(fetchLocaleList());
      
    }, [dispatch])
  );

  if (isLoading) return <Spinner />;
 
  return (    
    <AppLayout>     
      <Routes />
    </AppLayout>    
  );
};